<template>
  <div>
    <el-dialog title="裁剪图片" v-model="dialogVisible"  :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" @close="closeDialog" width="600px">
      <div class="avatar-container">
        <div class="avatar-crop">
          <VuePictureCropper
            :boxStyle="{
                width: '100%',
                height: '100%',
                backgroundColor: '#f8f8f8',
                margin: 'auto',
            }"
            :img="this.img"
            :options="{
                viewMode: 1,
                dragMode: 'move',
                cropBoxResizable: false,
                }"
            :presetMode="{
                mode: 'fixedSize',
                width: this.width,
                height: this.height,
            }">
          </VuePictureCropper> 
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div class="reupload">
            <span v-show="false"></span>
          </div>
          <div>
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="getCrop">确 定</el-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script>
  import VuePictureCropper, { cropper } from 'vue-picture-cropper'
  export default {
    components: {
      VuePictureCropper
    },
    props: {
      base64: {
        type: String,
      },
      type: {
        type:Number,
        default:0
      },
      width:{
        type:Number,
        default:100
      },
      height:{
        type:Number,
        default:100
      }
    },
    watch: {
      base64: {
        handler(val) {
          this.img = val // base64
        },
        deep: true,
        immediate: true,
      },
  },
  data() {
    return {
      dialogVisible:true,
      img:'',
      result:{
        dataURL:'',
        blobURL:'',
        blob:'',
        type:0,
      }
    }
  },
  mounted() {
    this.result.type = this.type
  },
    methods: {
      setSrcImageData(base64) {
        this.img = base64 // base64
      },
    //   upload(file) {
    //     const isIMAGE = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
    //     const isLt5M = file.raw.size / 1024 / 1024 < 5
    //     if (!isIMAGE) {
    //       this.$message({ showClose: true, message: '请选择 jpg、png 格式的图片',  type: 'warning'})
    //       return false
    //     }
    //     if (!isLt5M) {
    //       this.$message({ showClose: true,  message: '图片大小不能超过 5MB',  type: 'warning'})
    //       return false
    //     }
    //     let reader = new FileReader()
    //     reader.readAsDataURL(file.raw)
    //     reader.onload = e => {
    //       this.options.img = e.target.result // base64
    //     }
    //   },
      // 获取截图信息
      async getCrop() {
        let base64 = cropper.getDataURL()
        // 获取生成的blob文件信息
        let blob = await cropper.getBlob()

        // 把base64赋给结果展示区
        this.result.dataURL = base64
        this.result.blob = blob;
        try {
            this.result.blobURL = URL.createObjectURL(blob)
            this.iconHide = true
        } catch (e) {
            this.result.blobURL = ''
        }
        this.closeDialog();
        this.$emit('data',this.result)
      },
 
      // 关闭弹框
      closeDialog() {
        this.$emit('cancel')
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    .reupload {
      color: #409eff;
      cursor: pointer;
    }
  }
  .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 560px;
    height: 400px;
    background-color: #f0f2f5;
    margin-right: 10px;
    border-radius: 4px;
    .upload {
      text-align: center;
      margin-bottom: 24px;
    }
    .avatar-crop {
      width: 560px;
      height: 400px;
      position: relative;
      .crop-box {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }
  </style>
  
  
<template>
  <div>
    <vue-ueditor-wrap v-model="msg" :config="myConfig" ></vue-ueditor-wrap>
  </div>
</template>

<script>
import {ueditorUpload } from '../../api/api'
export default {
  data () {
    return {
      msg: '',
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 900,
        // 初始容器宽度
        initialFrameWidth: '100%',
        allowDivTransToP: false, // 解决自动将div转换成p标签
        elementPathEnabled: false, // 删除元素路径
        // catchRemoteImageEnable: false, //设置是否抓取远程图片
        // zIndex: 2000,
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: this.ueditorServerUrl(),
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/static/ueditor/',

        toolbars: [[
            "source", // 源代码
            "|",
            "undo", // 撤销
            "redo", // 重做
            "|",
            "bold", // 加粗
            "italic", // 斜体
            "underline", // 下划线
            "fontborder", // 字符边框
            "strikethrough", // 删除线
            "superscript", // 上标
            "subscript", // 下标
            "removeformat", // 清除格式
            "formatmatch", // 格式刷
            "autotypeset", // 自动排版
            "blockquote", // 引用
            "pasteplain", // 纯文本粘贴模式
            "|",
            "forecolor", // 字体颜色
            "backcolor", // 背景色
            "insertorderedlist", // 有序列表
            "insertunorderedlist", // 无序列表
            "selectall", // 全选
            "cleardoc", // 清空文档
            "|",
            "rowspacingtop", // 段前距
            "rowspacingbottom", // 段后距
            "lineheight", // 行间距
            "|",
            // 'customstyle', // 自定义标题
            // "fullscreen", // 全屏
            "paragraph", // 段落格式
            "fontfamily", // 字体
            "fontsize", // 字号
            "|",
            "directionalityltr", // 从左向右输入
            "directionalityrtl", // 从右向左输入
            "indent", // 首行缩进
            "|",
            "justifyleft", // 居左对齐
            "justifyright", // 居右对齐
            "justifycenter", // 居中对齐
            "justifyjustify", // 两端对齐
            "|",
            "touppercase", // 字母大写
            "tolowercase", // 字母小写
            "|",
            "link", // 超链接
            "unlink", // 取消链接
            "anchor", // 锚点
            "|",
            "imagenone", // 默认
            "imageleft", // 左浮动
            "imageright", // 右浮动
            "imagecenter", // 居中
            "|",
            "simpleupload", // 单图上传
            // "insertimage", // 多图上传
            "emotion", // 表情
            // 'scrawl', // 涂鸦
            // 'insertvideo', // 视频
            // 'music', // 音乐
            // 'attachment', // 附件
            "map", // Baidu地图
            "gmap", // Google地图
            // 'insertframe', // 插入Iframe
            // 'insertcode', // 代码语言
            // 'webapp', // 百度应用
            "pagebreak", // 分页
            // 'template', // 模板
            // 'background', // 背景
            "|",
            "horizontal", // 分隔线
            "date", // 日期
            "time", // 时间
            "spechars", // 特殊字符
            // 'snapscreen', // 截图
            // 'wordimage', // 图片转存
            "|",
            "inserttable", // 插入表格
            "deletetable", // 删除表格
            "insertparagraphbeforetable", // "表格前插入行"
            "insertrow", // 前插入行
            "deleterow", // 删除行
            "insertcol", // 前插入列
            "deletecol", // 删除列
            "mergecells", // 合并多个单元格
            "mergeright", // 右合并单元格
            "mergedown", // 下合并单元格
            "splittocells", // 完全拆分单元格
            "splittorows", // 拆分成行
            "splittocols", // 拆分成列
            "charts", // 图表
            "|",
            "print", // 打印
            "preview", // 预览
            "searchreplace", // 查询替换
          
            // 'drafts', // 从草稿箱加载
            // 'help' // 帮助
            // 'deletecaption', // 删除表格标题
            // 'inserttitle', // 插入标题
            // 'edittable', // 表格属性
            // 'edittd', // 单元格属性
            // 'edittip ' // 编辑提示
          ]],
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
    getMsg() {
      return this.msg
    },
    setMsg(msg) {
      this.msg = msg;
    },
    ueditorServerUrl() {
      return ueditorUpload();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
  <div class="file-upload">
    <el-upload 
      :action="uploadFile()" 
      :class="{ hide: hideUpload }" 
      ref="upload"
      list-type="picture-card" 
      :show-file-list="true" 
      :on-preview="handlePictureCardPreview" 
      :on-remove="handleRemove" 
      :before-upload="beforeAvatarUpload" 
      :on-success="handleAvatarSuccess" 
      :on-error="handleAvatarError" 
      :on-change="onChange" 
      :data="data" 
      :file-list="fileList" 
      :auto-upload="this.autoUpload" 
      :limit="this.limitCount">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog v-model="dialogVisible" title="查看图片"  :show-close="true" :close-on-click-modal="true" :close-on-press-escape="true" >
      <el-image style="" width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
 
</template>

<script>

export default {
  props: {
    uploadUrl: {
      type: String,
      default: "",
    },
    limitCount: {
      type: Number,
      default: 1,
    },
    files: {
      type: Array
    },
    data: {
      type: Object
    },
    autoUpload: {
      type: Boolean,
      default:true,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    files: {
      handler(val) {
        this.fileList = val;
        this.hideUpload = this.fileList.length >= parseInt(this.limitCount);
      },
      deep: true
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      hideUpload: false,
      ruleForm: {},
    };
  },
  created() { },
  mounted() {
    if (this.files.length > 0) {
      this.fileList = this.files;
      this.hideUpload = this.fileList.length >= parseInt(this.limitCount);
    }
  },
  methods: {
    //图片上传
    uploadFile() {
      return this.uploadUrl;
    },
    submit() {
      this.$refs.upload.submit();
    },

    //图片保存服务器上
    handleAvatarSuccess(res) {
      if (200 == res.code) {
        this.ruleForm = res.dataInfo;
      } else {
        this.ruleForm = {};
        this.$message.error(res.message || "图片上传失败！");
      }
      this.$emit("uploadSuccess", res);
    },
    handleAvatarError(error) {
      this.$message.error(error || "图片上传失败！");
    },
    handleRemove(file, fileList) {
      this.hideUpload = fileList.length >= parseInt(this.limitCount);
      var obj = {
        file: file,
        fileList: fileList,
      };

      if(file.status == 'success') {
        if(file.response != null) {
          obj.response = file.response.dataInfo
        }else {
          obj.response = file
        }
        this.$emit("deleteFile", obj, () => {
            this.deleteFile(obj);
          });
      }
    
      
    },
    deleteFile(obj) {
      this.$emit("deleteFileData", obj, () => {
        if (this.limitCount == 1) {
          this.fileList.splice(0, this.fileList.length);
        }
      });
    },
    handlePictureCardPreview(file) {
      this.hideUpload = this.fileList.length >= parseInt(this.limitCount);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onChange(file, fileList) {
      this.hideUpload = fileList.length >= parseInt(this.limitCount);
      this.$emit("onChange",file,this.type);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!(isJPG || isPNG)) {
        this.$message.error("图片格式不支持!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG) && isLt2M;
    },
  },
};
</script>

<style>
.hide .el-upload--picture-card {
  display: none;
}

.file-upload  .el-dialog__body{
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
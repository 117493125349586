import axios from 'axios';
//log图片路径
export const getLoginLogoName = params => {
  return axios.post('/v3iotcloud/v3iotcloud/login/getLoginLogoName', params)
};
//登录
export const sysLogin = params => {
  return axios.post('/v3iotcloud/v3iotcloud/login/sysLogin', params)
};
//高德地图地址
export const getGaoDeAddress = params => {
  return axios.get('https://restapi.amap.com/v3/config/district?key=12f29be6313d0aa47ecb0abeab9909aa', {
    params: params
  })
};
export const getDistrict = params => {
  return axios.get('https://restapi.amap.com/v3/config/district?key=12f29be6313d0aa47ecb0abeab9909aa&subdistrict=2&output=json', {
    params: params
  })
};

//获得机构信息
export const getCustomerInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/getCustomerInfo', params)
};
//修改机构信息
export const updateCustomerInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/updateCustomerInfo', params)
};

//获得机构列表信息
export const getUnitInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/getUnitInfos', params)
};

//新增B级机构信息
export const addUnitInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/addUnitInfo', params)
};

//删除B级机构信息
export const deleteUnitInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/deleteUnitInfo', params)
};

//授权资源信息
export const empowerResource = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/empowerResource', params)
};

//系统LOGO上传
export const uploadLogoFile = () => {
  return '/v3iotcloud/v3iotcloud/unit/uploadLogoFile'
};

export const uploadLogoFileEx = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/uploadLogoFile', params)
};

//删除LOGO 
export const deleteLogoFile = params => {
  return axios.post('/v3iotcloud/v3iotcloud/unit/deleteLogoFile', params)
};

//---------------------设置-安装位置---------------------------
//新增安装位置
export const addInPosInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/inpos/addInPosInfo', params)
};

//获取安装位置列表信息
export const getInPosInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/inpos/getInPosInfos', params)
};

//删除安装位置信息
export const deleteInPosInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/inpos/deleteInPosInfo', params)
};


//---------------------设置-管理人员---------------------------
//新增管理人员
export const addManagerInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/manager/addManagerInfo', params)
};

//获取管理人员列表信息
export const getManagerInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/manager/getManagerInfos', params)
};

//获取管理人员列表信息(外部调用)
export const getManagerInfosWithExternal = params => {
  return axios.post('/v3iotcloud/v3iotcloud/manager/getManagerInfosWithExternal', params)
};

//删除管理人员
export const deleteManagerInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/manager/deleteManagerInfo', params)
};

//获取管理人员
export const getManagerInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/manager/getManagerInfo', params)
};

//更新管理人员
export const updateManagerInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/manager/updateManagerInfo', params)
};


//---------------------日志记录--------------------------
//获取库存列表
export const getStockLogInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/log/getStockLogInfos', params)
};
//获取授权LOG列表
export const getEmpowerLogInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/log/getEmpowerLogInfos', params)
};

//获取使用LOG列表
export const getUseLogInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/log/getUseLogInfos', params)
};


//---------------------用户相关--------------------------
//新增用户信息
export const addUserInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/addUserInfo', params)
};
//获取用户列表
export const getUserInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/getUserInfos', params)
};
//删除用户信息
export const deleteUserInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/deleteUserInfo', params)
};
//获取用户详情
export const getUserInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/getUserInfo', params)
};
//编辑用户详情
export const editUserInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/editUserInfo', params)
};
//获取用户列表(外部调用)
export const getUserInfosWithExternal = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/getUserInfosWithExternal', params)
};
//重置用户密码
export const resetUserPassword = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/resetUserPassword', params)
};
//---------------------档案记录--------------------------
//新增用户详情
export const addUserRecordInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/record/addUserRecordInfo', params)
};
//获取用户档案列表信息
export const getUserRecordInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/record/getUserRecordInfos', params)
};
//删除用户档案记录信息
export const deleteUserRecordInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/record/deleteUserRecordInfo', params)
};
//获取用户档案记录信息
export const getUserRecordInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/record/getUserRecordInfo', params)
};
//编辑用户档案记录信息
export const editUserRecordInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/record/editUserRecordInfo', params)
};
//获取用户档案列表信息
export const getUserRecordInfosWithUser = params => {
  return axios.post('/v3iotcloud/v3iotcloud/record/getUserRecordInfosWithUser', params)
};

//---------------------用户分组--------------------------
//新增用户分组
export const addUserGroupInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/group/addUserGroupInfo', params)
};
//获取用户分组列表信息
export const getUserGroupInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/group/getUserGroupInfos', params)
};
//编辑用户分组列表信息
export const editUserGroupInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/group/editUserGroupInfo', params)
};
//删除用户分组列表信息
export const deleteUserGroupInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/group/deleteUserGroupInfo', params)
};
//新增或删除管理员信息
export const updateGroupManagerInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/group/updateGroupManagerInfo', params)
};
//新增或删除用户信息
export const updateGroupUserInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/group/updateGroupUserInfo', params)
};

//---------------------设备管理-一体机--------------------------

//设备鉴权
export const deviceAuth = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/deviceAuth', params)
};
//新增一体机
export const addAioInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/addAioInfo', params)
};
//获取一体机列表
export const getAioInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getAioInfos', params)
};
//删除一体机信息
export const deleteAioInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/deleteAioInfo', params)
};
//获取一体机信息
export const getAioInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getAioInfo', params)
};
//编辑一体机信息
export const editAioInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editAioInfo', params)
};
//获取一体机使用记录信息
export const getAioUseInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getAioUseInfos', params)
};

//---------------------设备管理-智能穿戴--------------------------
//新增智能穿戴
export const addWearInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/addWearInfo', params)
};
//获取智能穿戴列表信息
export const getWearInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getWearInfos', params)
};
//删除智能穿戴信息
export const deleteWearInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/deleteWearInfo', params)
};
//获得设备型号
export const getDeviceModelInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getDeviceModelInfos', params)
};
//编辑穿戴信息
export const editWearInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editWearInfo', params)
};
//获取设备使用信息
export const getWearUseInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getWearUseInfos', params)
};
//获取设备设置信息
export const getWearSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getWearSetInfo', params)
};
//编辑设备设置信息
export const editWearSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editWearSetInfo', params)
};
//同步数据
export const synWearInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/synWearInfo', params)
};

//---------------------设备管理-智能家居--------------------------
//新增智能家居
export const addSmartHomeInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/addSmartHomeInfo', params)
};
//获取智能家居列表信息
export const getSmartHomeInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getSmartHomeInfos', params)
};
//删除智能家居信息
export const deleteSmartHomeInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/deleteSmartHomeInfo', params)
};
//编辑智能家居信息
export const editSmartHomeInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editSmartHomeInfo', params)
};
//获得智能家居使用信息
export const getSmartHomeUseInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getSmartHomeUseInfos', params)
};
//获得智能家居设置信息
export const getSmartHomeSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getSmartHomeSetInfo', params)
};
//编辑智能家居设置信息
export const editSmartHomeSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editSmartHomeSetInfo', params)
};

//---------------------设备管理-智能床垫--------------------------------------------
//新增智能床垫
export const addMattressInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/addMattressInfo', params)
};
//获取智能床垫列表信息
export const getMattressInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getMattressInfos', params)
};
//删除智能床垫信息
export const deleteMattressInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/deleteMattressInfo', params)
};
//编辑智能家居信息
export const editMattressInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editMattressInfo', params)
};
//获得智能床垫使用信息
export const getMattressUseInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getMattressUseInfos', params)
};
//获得智能床垫信息
export const getMattressSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getMattressSetInfo', params)
};
//编辑智能床垫信息
export const editMattressSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editMattressSetInfo', params)
};

//获得床垫监控信息
export const getMattressMonitorInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/monitor/getMattressMonitorInfos', params)
};


//获得所有设备信息
export const getAllDeviceInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getAllDeviceInfos', params)
};

//获得所有设备信息
export const getDeviceMapInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getDeviceMapInfos', params)
};


//---------------------设备管理-毫米波雷达--------------------------------------------
//新增毫米波雷达
export const addRadarInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/addRadarInfo', params)
};
//获取毫米波雷达
export const getRadarInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getRadarInfos', params)
};
//删除毫米波雷达
export const deleteRadarInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/deleteRadarInfo', params)
};
//获取毫米波雷达详情
export const getRadarInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getRadarInfo', params)
};
//编辑毫米波雷达详情
export const editRadarInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editRadarInfo', params)
};
//获取毫米波雷达状态详情
export const getRadarStateInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getRadarStateInfo', params)
};
//获取毫米波雷达状态详情
export const getRadarUseInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getRadarUseInfos', params)
};
//获取毫米波雷达设置信息
export const getRadarSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/getRadarSetInfo', params)
};
//获取毫米波雷达设置信息
export const editRadarSetInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/device/editRadarSetInfo', params)
};









//---------------------安全监护----------------------------------------------------
//获取轨迹数据
export const getSgInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/sg/getSgInfos', params)
};
//新增监护设备信息
export const addSgWearInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/sg/addSgWearInfo', params)
};
//获取监护设备信息
export const getSgWearInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/sg/getSgWearInfos', params)
};
//获取监护设备信息
export const deleteSgWearInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/sg/deleteSgWearInfo', params)
};
//新增电子围栏
export const addFenceInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/fence/addFenceInfo', params)
};
//获取电子围栏列表信息
export const getFenceInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/fence/getFenceInfos', params)
};
//删除围栏信息
export const deleteFenceInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/fence/deleteFenceInfo', params)
};
//编辑围栏信息
export const editFenceInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/fence/editFenceInfo', params)
};
//更新围栏设备信息
export const updateFenceDeviceInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/fence/updateFenceDeviceInfo', params)
};
//获取围栏定位信息
export const getFenceDeviceSgInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/fence/getFenceDeviceSgInfos', params)
};
//获取围栏设备信息
export const getFenceDeviceInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/fence/getFenceDeviceInfos', params)
};
//---------------------预警管理--------------------------
//获取设备预警列表信息
export const getDeviceWarningInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/warning/getDeviceWarningInfos', params)
};
//获取设备预警列表信息
export const getDeviceWarningLngLatInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/warning/getDeviceWarningLngLatInfos', params)
};
//处理设备预警
export const operateDeviceWarning = params => {
  return axios.post('/v3iotcloud/v3iotcloud/warning/operateDeviceWarning', params)
};
//设备通知
export const getDeviceWarningNoticeInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/warning/getDeviceWarningNoticeInfos', params)
};
//设备处理
export const getDeviceWarningDealInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/warning/getDeviceWarningDealInfos', params)
};
//---------------------健康数据--------------------------
//获取血压列表数据
export const getBpInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getBpInfos', params)
};
//获取血糖列表数据
export const getBsInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getBsInfos', params)
};
//获取血脂列表数据
export const getBfInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getBfInfos', params)
};
//获取血氧列表数据
export const getBoInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getBoInfos', params)
};
//获取尿酸列表数据
export const getUaInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getUaInfos', params)
};
//获取心电列表数据
export const getEcgInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getEcgInfos', params)
};
//获取心电详细数据
export const getEcgInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getEcgInfo', params)
};
//获取体温列表数据
export const getEtgInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getEtgInfos', params)
};
//获取体脂列表数据
export const getFatInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getFatInfos', params)
};
//获取尿常规列表数据
export const getUrineInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getUrineInfos', params)
};
//获取糖化血红蛋白列表数据
export const getHbA1cInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getHbA1cInfos', params)
};
//获取步数列表数据
export const getStepInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getStepInfos', params)
};
//获取中医体质列表数据
export const getTcmInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getTcmInfos', params)
};
// 获取各体质特征数据
export const getTcmInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getTcmInfo', params)
};
//获取能力评估列表数据
export const getAbilityInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getAbilityInfos', params)
};
// 获取能力评估数据
export const getAbilityInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getAbilityInfo', params)
};
// 获取综合管理数据
export const getHealthAllInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getHealthAllInfos', params)
};
// 获取睡眠数据
export const getSleepInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getSleepInfos', params)
};

// 健康体检
export const getPhysicalReportInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/health/getPhysicalReportInfos', params)
};


//---------------------新闻资讯--------------------------
// 新增新闻
export const addNews = params => {
  return axios.post('/v3newsapi/v3newsapi/news/addNews', params)
};
//获得新闻列表
export const getNewsInfos = params => {
  return axios.post('/v3newsapi/v3newsapi/news/getNewsInfos', params)
};
//删除新闻
export const deleteNews = params => {
  return axios.post('/v3newsapi/v3newsapi/news/deleteNews', params)
};
//获得新闻信息
export const getNewsInfo = params => {
  return axios.post('/v3newsapi/v3newsapi/news/getNewsInfo', params)
};
//编辑新闻信息
export const editNews = params => {
  return axios.post('/v3newsapi/v3newsapi/news/editNews', params)
};

//---------------------文件上传--------------------------
export const fileUpload = () => {
  return '/fileapi/fileapi/file/fileUpload'
};
export const fileDelete = params => {
  return axios.post('/fileapi/fileapi/file/fileDelete', params)
};
export const fileUploadEx = params => {
  return axios.post('/fileapi/fileapi/file/fileUpload', params)
};
//百度富文件后台服务
export const ueditorUpload = () => {
  return '/ueditor/jsp/controller.jsp'
};


//--------------统计---------------
export const getUserCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getUserCount', params)
};
export const getPhysicalAppointCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getPhysicalAppointCount', params)
};
export const getPhysicalCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getPhysicalCount', params)
};
export const getHealthDataCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getHealthDataCount', params)
};
export const getDeviceCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getDeviceCount', params)
};
export const getDeviceStateDataCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getDeviceStateDataCount', params)
};
export const getWarningDataCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getWarningDataCount', params)
};
export const getWarningStateDataCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getWarningStateDataCount', params)
};
export const getAgeSexStatisInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getAgeSexStatisInfo', params)
};
export const getPhysicalReportInfosWithDay = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getPhysicalReportInfosWithDay', params)
};
export const getPhysicalReportInfosWithMonth = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getPhysicalReportInfosWithMonth', params)
};
export const getPhysicalReportInfosWithQuarter = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getPhysicalReportInfosWithQuarter', params)
};
export const getPhysicalReportInfosWithYear = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getPhysicalReportInfosWithYear', params)
};
export const getDistrictUserCountInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getDistrictUserCountInfos', params)
};
export const getDistrictDeviceCountInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getDistrictDeviceCountInfos', params)
};

export const getDeviceTypeReportInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getDeviceTypeReportInfos', params)
};
export const getWarningTypeReportInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getWarningTypeReportInfos', params)
};
export const getDistrictArea = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getDistrictArea', params)
};
export const setDistrictArea = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/setDistrictArea', params)
};
export const getWarningMultiCount = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getWarningMultiCount', params)
};
export const getWarningInfosWithDay = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getWarningInfosWithDay', params)
};
export const getWarningInfosWithMonth = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getWarningInfosWithMonth', params)
};
export const getHealthDataTypeReportInfos = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getHealthDataTypeReportInfos', params)
};

export const getUnitStatisInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/statistics/getUnitStatisInfo', params)
};


export const getWarningNoticeInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/getWarningNoticeInfo', params)
};

export const editWarningNoticeInfo = params => {
  return axios.post('/v3iotcloud/v3iotcloud/user/editWarningNoticeInfo', params)
};





import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [
  {
    path: '/login',name: '',hidden: true,component: () => import('../views/login/Login.vue')
  },
  // {
  //   path: '/user-report-full-screen',name: '',hidden: true,component: () => import('../views/report/user-report/UserReport.vue')
  // },
  {
    path: '/health-report-full-screen',name: '',hidden: true,component: () => import('../views/report/health-report/HealthReport.vue')
  },
  {
    path: '/device-report-full-screen',name: '',hidden: true,component: () => import('../views/report/device-report/DeviceReport.vue')
  },
  {
    path: '/operate-report-full-screen',name: '',hidden: true,component: () => import('../views/report/operate-report/OperateReport.vue')
  },
  {
    path: '/sleep-monitor-full-screen',name: '',hidden: true,component: () => import('../views/health/sleep/SleepMonitor.vue')
  },
  {
    path: '/warn-monitor-full-screen',name: '',hidden: true,component: () => import('../views/warning/warn-monitor/WarnMonitor.vue'),
    meta: {role: 'AB'}
  },
  {
    path: '/user-report-full-screen',name: '',hidden: true,component: () => import('../views/report/user-report/UserMainFullScreenReport.vue'),
  },
  {
    path: '/', component: () => import('../views/Home.vue'),name: '数据中心',iconCls: 'iconfont icon-shujuzhongxin',leaf: false, hidden: false,
    children: [
      // {path: '/index-a',component: () => import('../views/index/AIndex.vue'), name: '综合报表', meta: {role: 'A'}},
      // {path: '/index-b', component: () => import('../views/index/BIndex.vue'), name: '综合报表 ', meta: {role: 'B'}},
      // {path: '/index-a',component: () => import('../views/report/user-report/UserMainAReport.vue'), name: '数据大屏A', meta: {role: 'A'}},
      // {path: '/index-b',component: () => import('../views/report/user-report/UserMainBReport.vue'), name: '数据大屏B', meta: {role: 'B'}},
      {path: '/index',component: () => import('../views/report/user-report/UserMainReport.vue'), name: '数据大屏', meta: {role: 'AB'}},
      {path: '/iufo',component: () => import('../views/report/iufo-report/Iufo.vue'), name: '综合报表', meta: {role: 'AB'}},
     
      // {path: '/health-report',component: () => import('../views/report/health-report/HealthReport.vue'), name: '健康报表', meta: {role: 'AB'}},
      // {path: '/device-report',component: () => import('../views/report/device-report/DeviceReport.vue'), name: '设备报表', meta: {role: 'AB'}},
      // {path: '/operate-report',component: () => import('../views/report/operate-report/OperateReport.vue'), name: '运营报表', meta: {role: 'AB'}},
    ],
    meta: {role: 'AB'}
  },
  // {
  //   path: '/', component: () => import('../views/Home.vue'),name: '运营管理',iconCls: 'iconfont icon-yunyingzhongxin',leaf: false, hidden: false,
  //   children: [
  //     {path: '/health-portrait',component: () => import('../views/operate/health-portrait/HealthPortrait.vue'), name: '健康画像', meta: {role: 'AB'}},
  //     {path: '/push-record',component: () => import('../views/operate/push-record/PushRecord.vue'), name: '服务推送', meta: {role: 'AB'}},
  //     {path: '/agency',component: () => import('../views/operate/agency/Agency.vue'), name: '服务机构', meta: {role: 'AB'}},
  //   ],
  //   meta: {role: 'AB'}
  // },
  {
    path: '/',component: () => import('../views/Home.vue'), name: '用户管理',iconCls: 'iconfont icon-yonghu',leaf: false,hidden: false,
    children: [
      {path: '/user',component: () => import('../views/user/user/User.vue'),name: '用户列表',meta: { role: 'AB'}},
      {path: '/group',component: () => import('../views/user/group/Group.vue'),name: '用户分组',meta: {role: 'B'}},
      {path: '/archive-record',component: () => import('../views/user/record/ArchiveRecord.vue'),name: '档案记录',meta: {role: 'AB'}}
    ],
    meta: {role: 'AB'}
  },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '健康管理',iconCls: 'iconfont icon-navicon-jkgl',leaf: false, hidden: false,
    children: [
      {path: '/physical',component: () => import('../views/health/physical/Physical.vue'),name: '体检数据',meta: {role: 'AB'}},
      {path: '/tcm',component: () => import('../views/health/tcm/Tcm.vue'),name: '中医体质',meta: {role: 'AB'}},
      {path: '/capability',component: () => import('../views/health/capability/Capability.vue'),name: '能力评估',meta: {role: 'AB'}},
      // {path: '/sleep-report',component: () => import('../views/health/sleep/SleepReport.vue'),name: '睡眠报告',meta: {role: 'AB'}},
      {path: '/sleep-monitor',component: () => import('../views/health/sleep/SleepMonitor.vue'),name: '睡眠监控',meta: {role: 'B'}}
    ],
    meta: {role: 'AB'}
  },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '安全监护',iconCls: 'iconfont icon-dingwei',leaf: false,hidden: false,
    children: [
      {path: '/fence',component: () => import('../views/sg/fence/Fence.vue'),name: '电子围栏',meta: {role: 'B'}},
      {path: '/location',component: () => import('../views/sg/location/Location.vue'),name: '实时定位',meta: {role: 'B'}}, 
      {path: '/trace',component: () => import('../views/sg/location/Trace.vue'),name: '轨迹查询',meta: {role: 'B'}}, 
      {path: '/navigation',component: () => import('../views/sg/location/Navigation.vue'),name: '定位导航',meta: {role: 'B'}},
      // {path: '/map-demo',component: () => import('../views/sg/map-demo/MapDemo.vue'),name: '地图测试',meta: {role: 'B'}}, 
    ],
    meta: {role: 'B'}
  },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '预警管理',iconCls: 'iconfont icon-yujing',leaf: false,hidden: false,
    children: [
      {path: '/device-warning',component: () => import('../views/warning/device-warning/DeviceWarning.vue'),name: '设备预警',meta: {role: 'AB'}},
      // {path: '/health-warning',component: () => import('../views/warning/health-warning/HealthWarning.vue'),name: '健康预警',meta: {role: 'AB'}}, 
      {path: '/warn-monitor',component: () => import('../views/warning/warn-monitor/WarnMonitor.vue'),name: '预警监控',meta: {role: 'B'}} 
    ],
    meta: {role: 'AB'}
  },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '设备管理',iconCls: 'iconfont icon-shebei',leaf: false, hidden: false,
    children: [
      {path: '/device-map',component: () => import('../views/device/device-map/DeviceMap.vue'),name: '设备地图',meta: {role: 'AB'}},
      {path: '/wear-device',component: () => import('../views/device/wear/Wear.vue'),name: '穿戴设备',meta: {role: 'AB'}},
      // {path: '/health-device',component: () => import('../views/device/health/HealthDevice.vue'),name: '健康设备',leaf: true,hidden: false,
      //   children: [
      //     {path: '/mattress',component: () => import('../views/device/health/mattress/Mattress.vue'),name: '智能床垫',meta: {role: 'AB'}},
      //     {path: '/aio',component: () => import('../views/device/health/aio/Aio.vue'),name: '健康一体机',meta: {role: 'AB'}}
      //   ],
      //   meta: {role: 'AB'}
      // },
      {path: '/radar',component: () => import('../views/device/radar/Radar.vue'),name: '毫米波雷达',meta: {role: 'AB'}},
      {path: '/mattress',component: () => import('../views/device/health/mattress/Mattress.vue'),name: '智能床垫',meta: {role: 'AB'}},
      {path: '/aio',component: () => import('../views/device/health/aio/Aio.vue'),name: '健康一体机',meta: {role: 'AB'}},
      {path: '/smart-home',component: () => import('../views/device/smart-home/SmartHome.vue'),name: '智能家居',meta: {role: 'AB'}},
      
    ],
    meta: {role: 'AB'}
  },
  // {
  //   path: '/',component: () => import('../views/Home.vue'),name: '新闻资讯',iconCls: 'iconfont icon-xinwen-zixun-02',leaf: false, hidden: false,
  //   children: [
  //     {path: '/news-published',component: () => import('../views/news/Published.vue'),name: '已\u0020发\u0020布',meta: {role: 'A'}},
  //     {path: '/news-to-be-released',component: () => import('../views/news/ToBeReleased.vue'),name: '待\u0020发\u0020布',meta: {role: 'A'}},
  //   ],
  //   meta: {role: 'A'}
  // },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '日志记录',iconCls: 'iconfont icon-rizhi',leaf: false, hidden: false,
    children: [
      {path: '/stock',component: () => import('../views/log/stock/Stock.vue'),name: '库存记录',meta: {role: 'A'}},
      {path: '/empower',component: () => import('../views/log/empower/Empower.vue'),name: '授权记录',meta: {role: 'AB'}},
      {path: '/use-record',component: () => import('../views/log/use/Use.vue'),name: '使用记录',meta: {role: 'AB'}},
      // {path: '/unit-cropper',component: () => import('../views/set/cropper/Tailoring.vue'), name: '图片裁剪', meta: {role: 'AB'}},
      // {path: '/cropper',component: () => import('../views/set/cropper/CropperView.vue'), name: '图片裁剪2', meta: {role: 'AB'}}
    ],
    meta: {role: 'AB'}
  },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '',iconCls: 'iconfont icon-jigou', leaf: true, hidden: false,
    children: [
      {path: '/unit',component: () => import('../views/unit/Unit.vue'), name: '机构管理', meta: {role: 'A'}}
    ],
    meta: {role: 'A'}
  },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '基础设置',iconCls: 'iconfont icon-shezhi',leaf: false, hidden: false,
    children: [
      {path: '/inspos',component: () => import('../views/set/inspos/Inspos.vue'),name: '安装位置', meta: {role: 'B'}},
      {path: '/manager',component: () => import('../views/set/manager/Manager.vue'),name: '管理人员',meta: {role: 'B'}},
      {path: '/unit-info',component: () => import('../views/set/unit-info/UnitBInfo.vue'),name: '机构信息',meta: {role: 'B'}}
    ],
    meta: {role: 'B'} 
  },
  {
    path: '/',component: () => import('../views/Home.vue'),name: '机构设置',iconCls: 'iconfont icon-jibenziliao', leaf: false, hidden: false,
    children: [
      {path: '/unit-ainfo',component: () => import('../views/set/unit-info/UnitAInfo.vue'), name: '机构资料', meta: {role: 'A'}},
      // {path: '/unit-set',component: () => import('../views/set/app/AppSet.vue'), name: '机构设置', meta: {role: 'A'}}
    ],
    meta: {role: 'A'}
  },
  ////个人档案
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-jibenziliao', leaf: true, hidden: true,
    children: [
      {path: '/user-base',component: () => import('../views/archives/UserBase.vue'), name: '个人档案', meta: {role: 'U'}}
    ],
    meta: {role: 'U'}
  },
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-jibenziliao', leaf: true, hidden: false,
    children: [
      {path: '/user-base-info',component: () => import('../views/archives/user/UserInfo.vue'), name: '个人资料 ', meta: {role: 'U'}}
    ],
    meta: {role: 'U'}
  },
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-bijijilu', leaf: true, hidden: false,
    children: [
      {path: '/user-record',component: () => import('../views/user/record/UserArchiveRecord.vue'),name: '档案记录 ',meta: {role: 'U'}},
    ],
    meta: {role: 'U'}
  },
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-zhongyitizhibianshi', leaf: true, hidden: false,
    children: [
      {path: '/user-tcm',component: () => import('../views/health/tcm/UserTcm.vue'),name: '中医体质 ',meta: {role: 'U'}},
    ],
    meta: {role: 'U'}
  },
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-zilinenglipinggu', leaf: true, hidden: false,
    children: [
      {path: '/user-capability',component: () => import('../views/health/capability/UserCapability.vue'),name: '能力评估 ',meta: {role: 'U'}},
    ],
    meta: {role: 'U'}
  },
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-locus', leaf: true, hidden: false,
    children: [
      {path: '/user-trace',component: () => import('../views/sg/location/UserTrace.vue'),name: '轨迹查询 ',meta: {role: 'U'}},
    ],
    meta: {role: 'U'}
  },
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-dingwei', leaf: true, hidden: false,
    children: [
      {path: '/user-location',component: () => import('../views/sg/location/UserLocation.vue'),name: '实时定位 ',meta: {role: 'U'}},
    ],
    meta: {role: 'U'}
  },

  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-zhinengjiajuAPP--jiankong-copy', leaf: true, hidden: false,
    children: [
      {path: '/use-device-warning',component: () => import('../views/warning/device-warning/UserDeviceWarning.vue'),name: '设备预警 ',meta: {role: 'U'}},
    ],
    meta: {role: 'U'}
  },
  // {
  //   path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-yujing', leaf: true, hidden: false,
  //   children: [
  //     {path: '/user-health-warning',component: () => import('../views/warning/health-warning/HealthWarning.vue'), name: '健康预警 ', meta: {role: 'U'}}
  //   ],
  //   meta: {role: 'U'}
  // },
  // {
  //   path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-shuimian', leaf: true, hidden: false,
  //   children: [
  //     {path: '/user-sleep-report',component: () => import('../views/health/sleep/UserSleepReport.vue'),name: '睡眠报告 ',meta: {role: 'U'}},
  //   ],
  //   meta: {role: 'U'}
  // },
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '',iconCls: 'iconfont icon-shebei', leaf: true, hidden: false,
    children: [
      {path: '/user-smart-device',component: () => import('../views/device/smart-device/SmartDevice.vue'), name: '智能设备 ', meta: {role: 'U'}}
    ],
    meta: {role: 'U'}
  },
 
  {
    path: '/',component: () => import('../views/UserHome.vue'),name: '体检数据 ',iconCls: 'iconfont icon-tijian',leaf: false, hidden: false,
      children: [
        {path: '/user-bp',component: () => import('../views/archives/physical/bp/Bp.vue'),name: '血压',meta: {role: 'U'}},
        {path: '/user-bs',component: () => import('../views/archives/physical/bs/Bs.vue'),name: '血糖',meta: {role: 'U'}},
        {path: '/user-tc',component: () => import('../views/archives/physical/tc/Tc.vue'),name: '血脂',meta: {role: 'U'}},
        {path: '/user-bo',component: () => import('../views/archives/physical/bo/Bo.vue'),name: '血氧',meta: {role: 'U'}},
        {path: '/user-ua',component: () => import('../views/archives/physical/ua/Ua.vue'),name: '尿酸',meta: {role: 'U'}},
        // {path: '/user-heart',component: () => import('../views/archives/physical/heart/Heart.vue'),name: '心率',meta: {role: 'U'}},
        {path: '/user-ecg',component: () => import('../views/archives/physical/ecg/Ecg.vue'),name: '心电',meta: {role: 'U'}},
        {path: '/user-etg',component: () => import('../views/archives/physical/etg/Etg.vue'),name: '体温',meta: {role: 'U'}},
        {path: '/user-body-fat',component: () => import('../views/archives/physical/fat/BodyFat.vue'),name: '体脂',meta: {role: 'U'}},
        {path: '/user-urine',component: () => import('../views/archives/physical/urine/Urine.vue'),name: '尿液',meta: {role: 'U'}},
        {path: '/user-aba1c',component: () => import('../views/archives/physical/aba1c/Aba1c.vue'),name: '糖化',meta: {role: 'U'}},
        {path: '/user-step',component: () => import('../views/archives/physical/step/Step.vue'),name: '计步',meta: {role: 'U'}},
      ],
    meta: {role: 'U'}
  },



]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
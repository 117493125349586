import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import VueUeditorWrap from 'vue-ueditor-wrap';
import session from './store/store'
import elementPlus from './plugins/element'
import title from './components/title/ls-title.vue'
import titleHeader from './components/title/ls-title-header.vue'
import pagination from './components/pagination/ls-pagination.vue'
import address from './components/address/ls-address.vue'
import fileupload from './components/file-upload/ls-file-upload.vue'
import leadingIn from './components/file-upload/ls-leading-in.vue'
import lsCropper from './components/cropper/ls-cropper.vue'
import lsUeditor from './components/ueditor/ls-ueditor.vue'
// import until from './until/until';
import print from 'vue3-print-nb'
import '@/assets/icon/iconfont.css'
import '@/assets/iconr/iconfont-sleep.css'
import md5 from 'js-md5'



// until.screenCompatible();
session.setHost(window.location.host)
const app = createApp(App)
app.component('ls-title', title)
app.component('ls-title-header', titleHeader)
app.component('ls-pagination', pagination)
app.component('ls-address', address)
app.component('ls-file-upload', fileupload)
app.component('ls-leading-in', leadingIn)
app.component('ls-cropper', lsCropper)
app.component('ls-ueditor', lsUeditor)
app.use(elementPlus)
app.use(router)
app.use(print)
app.use(VueUeditorWrap)
app.mount('#app')

app.config.globalProperties.$md5 = md5;

//1:正式  2：测试
// app.config.globalProperties.$relaseOn = 2;



 

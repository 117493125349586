export default {
  /**
   * 设置Token缓存
   */
  setToken: function (data) {
      sessionStorage.setItem("$token", data);
  },
  /**
   * 
   * @returns 获取Token缓存
   */
  getToken: function () {
      return sessionStorage.getItem("$token");
  },
  /**
   * 设置host缓存
   */
  setHost: function (data) {
      sessionStorage.setItem("$host", data);
  },
  /**
   * 
   * @returns 获取host缓存
   */
  getHost: function () {
      return sessionStorage.getItem("$host");
  },
  /**
   * 设置登录信息缓存
   */
  setLoginInfo: function (data) {
      sessionStorage.setItem("$loginInfo", JSON.stringify(data));
  },
  /**
   * 
   * @returns 获取登录信息缓存
   */
  getLoginInfo: function () {
      return JSON.parse(sessionStorage.getItem("$loginInfo"));
  },
  /**
   * 设置coustomUuid缓存
   */
  setCustomerUuid: function (data) {
      sessionStorage.setItem("$coustomUuid", data);
  },
  /**
   * 
   * @returns 获取coustomUuid缓存
   */
  getCustomerUuid: function () {
      return sessionStorage.getItem("$coustomUuid");
  },

  setCustomerData: function (data) {
    sessionStorage.setItem("$customer", JSON.stringify(data));
  },

  getCustomerData: function () {
    return JSON.parse(sessionStorage.getItem("$customer"));
  },

  setSysName: function (data) {
    sessionStorage.setItem("$sysName", data);
  },

  getSysName: function () {
    return sessionStorage.getItem("$sysName");
  },

  setSysLogoUrl: function (data) {
    sessionStorage.setItem("$sysLogoUrl", data);
  },

  getSysLogoUrl: function () {
    return sessionStorage.getItem("$sysLogoUrl");
  },


   //清楚所有缓存
  clearAll:function(){
    sessionStorage.clear();
  }

};
<template>
  <div>
    <el-config-provider :locale="locale">
      <el-pagination :currentPage="pCurrentPage" :page-size="pSize" :page-sizes="[10, 20, 30, 50]"   :background="true" :layout="layout" :total="pTotal" @size-change="handleSizeChange" @current-change="handleCurrentChange"> </el-pagination>
    </el-config-provider>
  </div>
</template>

<script>
//引入vue方法
// import { ElConfigProvider } from 'element-plus'
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn"
export default {
  props: {
    currentPage: { type: Number },
    size: { type: Number },
    total: { type: Number },
    layout:{
      type: String,
      default:'total, sizes, prev, pager, next, jumper'
    }
  },
  // components: {
  //   ElConfigProvider
  // },
  watch: {
    size: function () {
      this.pSize = this.size;
    },
    total: function () {
      this.pTotal = this.total;
    },
    currentPage: function () {
      this.pCurrentPage = this.currentPage;
    }
  },
  data() {
    return {
      pSize: 0,
      pCurrentPage: 1,
      pTotal: 0,
      locale: zhCn,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.pSize = this.size;
      this.pCurrentPage = this.currentPage;
      this.pTotal = this.total;
    },
    /*
     *size改变事件
     */
    handleSizeChange(val) {
      this.pSize = val;
      this.$emit('size-change', val)
    },
    /*
     *当前页改变事件
     */
    handleCurrentChange(val) {
      this.pCurrentPage = val;
      this.$emit('cur-change', val)
    }
  }
}
</script>

<style>
</style>
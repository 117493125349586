<template>
  <div>
    <el-dialog :title="title" v-model="dialogVisible" width="35%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div>
        <el-upload class="upload-demo" ref="upload" :limit="1" accept=".xls,.xlsx" :action="action" :data="fileData" :before-upload="beforeUploadFile" :on-change="fileChange" :on-remove="fileRemove" :on-exceed="exceedFile" :on-success="handleSuccess" :on-error="handleError" :file-list="fileList" :auto-upload="false">
          <!-- <template #trigger> -->
          <el-button size="small" type="primary" style="width: 80px">选取文件</el-button>
          <!-- </template> -->
          <template #tip>
            <div class="el-upload__tip">只能上传xls、xlsx文件，且不超过2M</div>
          </template>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <span style="cursor: pointer; float: left; font-size: 15px;margin-top: 8px; margin-right: 40px;">
            <i class="el-icon-download"></i>
            <a :href="downFileUrl" download="模板.xls" style="text-decoration: none; color: #606266">下载模板</a>
          </span>
          <span v-show="errorUp" style="cursor: pointer;float: left;font-size: 15px;margin-top: 8px;color: #66b1ff;">
            <i class="el-icon-warning-outline"></i>{{ uploadInfoMes}}
            </span>
          <el-button @click="cancelPage()" size="small">取 消</el-button>
          <el-button type="primary" @click="submitUpload()" size="small" :style="{ width: uploadLoading == true ? '100px;' : '70px' }">上传</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    action: {
      type: String
    },
    downFileUrl: {
      type: String
    },
    fileData: {
      type: Object
    }
  },
  data() {
    return {
      dialogVisible: true,
      uploadInfoMes: '',
      errorUp: false,
      fileList: [], //excel文件列表
      size: 10, //每页显示的条数
      total: 0, //列表总条数
      currentPage: 1, //当前页码
      uploadLoading: false,
      btnFirm: false,//是否为重复提交
    };
  },
  mounted() {
  },
  methods: {
    /**
     * 取消页面
     */
    cancelPage() {
      this.$emit("close");
      this.$emit("refresh");
    },
    /**
     * 上传
     */
    submitUpload() {
      if (this.btnFirm == true) {
        this.$message.warning("请勿重复提交");
        return;
      }
      if (this.fileList.length == 0) {
        this.$message.warning("请选择文件");
        return;
      }
      this.uploadLoading = true;
      this.btnFirm = true;
      this.$nextTick(() => {
        this.$refs["upload"].submit();
      });
    },
    /**
     * 文件上传之前
     */
    beforeUploadFile(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xls" && extension !== "xlsx") {
        this.$message.warning("只能上传后缀是.xls .xlsx的文件");
      }
      if (size > 10) {
        this.$message.warning("文件大小不得超过2M");
      }
    },
    /**
     * 文件状态改变时的钩子
     */
    fileChange(file, fileList) {
      this.fileList = fileList;
    },
    /**
     * 文件移除
     */
    fileRemove() {
      this.fileList.splice(0, this.fileList.length);
      this.btnFirm = false;
    },
    // 文件超出个数限制时的钩子
    exceedFile(files) {
      this.$message.warning(
        `只能选择 1 个文件，当前共选择了 ${files.length + this.fileList.length
        } 个`
      );
    },
    // 文件上传成功时的钩子
    handleSuccess(res) {
      // this.$message.success("文件上传成功");

      this.uploadLoading = false;
      if (res.code == 200) {
        this.errorUp = true;
        this.uploadInfoMes = res.message;
      } else {
        this.$message.error(res.message);
      }
    },
    // 文件上传失败时的钩子
    handleError() {
      this.uploadLoading = false;
      this.$message.error("文件上传失败");
      this.errorUp = true;
    },
  },
};
</script>

<style>
</style>
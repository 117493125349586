<template>
  <!--标题-->
  <div style="padding: 20px 0px">
    <span class="bigTit"> {{title}} </span>
    <span class="bigE">{{enTitle}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    enTitle: {
      type: String
    }
  }
}
</script>

<style scoped>
.bigTit {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 3px;
}
.bigE {
  color: #9d9d9d;
  font-size: 18px;
}
</style>
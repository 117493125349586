export default {
    // 非空校验
    isNULL(value) {
        if (value == "undefined" || value == "" || value == null || value == undefined) {
            return true;
        } else {
            return false;
        }
    },
    // 获取地址栏参数
    getUrl() {
        var search = location.search; //获取location的search属性，保存在search中
        var params = {}; //创建空对象params
        if (search != "") { //如果search不是空字符串
            search.slice(1).split("&").forEach( //search去开头?，按&切割为数组，forEach
                function (val) {
                    var arr = val.split("="); //将当前元素值按=切割，保存在arr中
                    params[arr[0]] = arr[1]; //向params中添加一个元素,属性名为arr[0],值为arr[1]
                }
            );
        }
        return params;
    },

    /*1.用浏览器内部转换器实现html转码*/
    htmlEncode: function (html) {
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)
        (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
        //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
        var output = temp.innerHTML;
        temp = null;
        return output;
    },
    /*2.用浏览器内部转换器实现html解码*/
    htmlDecode: function (text) {
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
        temp.innerHTML = text;
        //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    },
    /**
     * 将str中的html符号转义,将转义“'，&，<，"，>”五个字符
     * @method unhtml
     * @param { String } str 需要转义的字符串
     * @return { String } 转义后的字符串
     * @example
     * ```javascript
     * var html = '<body>&</body>';
     *
     * //output: &lt;body&gt;&amp;&lt;/body&gt;
     * console.log( UE.utils.unhtml( html ) );
     *
     * ```
     */
    unhtml(str, reg) {
        return str ? str.replace(reg || /[&<">'](?:(nbsp|shy|gt|lt|amp|apos|quot|quot|iexcl|cent|pound|curren|yen|brvbar|sect|uml|copy|ordf|laquo|not|shy|reg|macr|deg|plusmn|sup2|sup3|acute|micro|para|middot|cedil|sup1|ordm|raquo|frac14|frac12|frac34|iquest|times|divide|fnof|bull|hellip|prime|Prime|oline|frasl|weierp|image|real|trade|alefsym|larr|uarr|rarr|darr|harr|crarr|lArr|uArr|rArr|dArr|hArr|forall|part|exist|empty|nabla|isin|notin|ni|prod|sum|minus|lowast|radic|prop|infin|ang|and|or|cap|cup|int|there4|sim|cong|asymp|ne|equiv|le|ge|sub|sup|nsub|sube|supe|oplus|otimes|perp|sdot|lceil|rceil|lfloor|rfloor|lang|rang|loz|spades|clubs|hearts|diams|circ|tilde|ensp|emsp|thinsp|zwnj|zwj|lrm|rlm|ndash|mdash|lsquo|rsquo|sbquo|ldquo|rdquo|bdquo|dagger|Dagger|permil|lsaquo|rsaquo|euro|Agrave|Aacute|Acirc|Atilde|Auml|Aring|AElig|Ccedil|Egrave|Eacute|Ecirc|Euml|Igrave|Iacute|Icirc|Iuml|ETH|Ntilde|Ograve|Oacute|Ocirc|Otilde|Ouml|Oslash|Ugrave|Uacute|Ucirc|Uuml|Yacute|THORN|szlig|agrave|aacute|acirc|atilde|auml|aring|aelig|ccedil|egrave|eacute|ecirc|euml|igrave|iacute|icirc|iuml|eth|ntilde|ograve|oacute|ocirc|otilde|ouml|oslash|ugrave|uacute|ucirc|uuml|yacute|thorn|yuml|OElig|oelig|Scaron|scaron|Yuml|Alpha|Beta|Gamma|Delta|Epsilon|Zeta|Eta|Theta|Iota|Kappa|Lambda|Mu|Nu|Xi|Omicron|Pi|Rho|Sigma|Tau|Upsilon|Phi|Chi|Psi|Omega|alpha|beta|gamma|delta|epsilon|zeta|eta|theta|iota|kappa|lambda|mu|nu|xi|omicron|pi|rho|sigmaf|sigma|tau|upsilon|phi|chi|psi|omega|thetasym|upsih|piv|#39|#d+);)?/g, function (a, b) {
            if (b) {
                return a;
            } else {
                return {
                    '<': '&lt;',
                    '&': '&amp;',
                    '"': '&quot;',
                    '>': '&gt;',
                    "'": '&#39;'
                } [a]
            }

        }) : '';
    },
    //屏幕兼容
    screenCompatible() {
        // 根据屏幕大小缩放
        if (/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
            if (window.screen.width <= '400' && window.screen.width > '380') {
                document.write('<body style="zoom: 73%">');
            } else if (window.screen.width <= '380' && window.screen.width > '370') {
                document.write('<body style="zoom: 65%">');
            } else if (window.screen.width <= '370') {
                document.write('<body style="zoom: 61%">');
            } else if (window.screen.width > '500') {
                document.write('<body style="zoom: 100%">');
            } else {
                document.write('<body style="zoom: 75%">');
            }
        } else {
            if (window.screen.width <= '1440' && window.screen.width > '1340') {
                document.write('<body style="zoom:85%">');
            } else if (window.screen.width <= '1340' && window.screen.width > '1280') {
                document.write('<body style="zoom: 85%">');//78
            } else if (window.screen.width <= '1280' && window.screen.width > '1024') {
                document.write('<body style="zoom: 85%">');//75
            } else if (window.screen.width <= '1024') {
                document.write('<body style="zoom: 85%">');//65
            }
        }
    },

    //核对是否有特殊字符
    checkSpecialCharacters(content) {
        var pattern = new RegExp("[`~%@#^~@#￥……&——*()（）$!！,，。+=]");
        if ((pattern.test(content))) {
            return true
        } else {
            return false
        }
    }
}
<template>
  <div id="App" style="height: 100%;width: 100%;">
    <!-- <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition> -->
    <!-- <router-view v-slot="{ Component }">
      <transition name="el-zoom-in-top">
        <component :is="Component" />
      </transition>
    </router-view>  -->

    <router-view></router-view>
    
    
  </div>
  
</template>


<script>
import untils from '../src/until/until'
import sessions from '../src/store/store'
export default {
  name: 'App',
  components: {
  },
  
  mounted() {
    let len = window.location.origin.length;
    let path = window.location.href.substring(len);
    if (untils.isNULL(sessions.getToken())) {
      if (this.$route.path != '/login') {
        this.$router.push('/login')
      }
    }
    else {
      if(path.length <= 1)  {
        this.$router.push('/login')
      }
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  height: 100%;
  width: 100%;
  /* margin-top: 60px; */
}
html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0px;
}

/* 默认颜色 */
:root {
  --el-color-primary: #285df0 !important;
}


/* input输入框公共样式 */
.el-input {
  --el-input-focus-border: #285df0 !important;
}

/* pagination分页公共样式 */
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #285df0 !important;
}
.el-pagination {
  --el-pagination-hover-color: #285df0 !important;
}

/* select下拉框公共样式 */
.el-select-dropdown__item.selected {
  color: #285df0 !important;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #285df0 !important;
}

/* dialog框公共样式 */
.el-dialog {
  border-radius: 10px !important;
}
.el-dialog__header {
  border-radius: 10px 10px 0px 0px;
  background-color: #285df0;
}
.el-dialog__title {
  color: white !important;
}

/* button提交按钮公共样式 */
.el-button--primary {
  background-color: #285df0 !important;
}
.el-button--primary.is-plain {
  background-color: white !important;
  border-color: #285df0 !important;
}
.el-button--primary.is-plain span {
  color: #285df0 !important;
}
</style>

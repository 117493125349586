<template>
  <div style="display: flex">
    <el-select v-model="selectProvince"  value-key="name" @change="changeProvice($event)" placeholder="省" style="margin-right: 5px;width: (100 / this.number)%;" v-show="num!=undefined&&num>=1" :size="size">
      <el-option v-for="item in provinces" :key="item.name" :label="item.name" :value="item"></el-option>
    </el-select>
    <el-select v-model="selectCity"  value-key="name" @change="changeCity($event)" placeholder="市" style="margin-right: 5px;width: (100 / this.number)%;" v-show="num!=undefined&&num>=2" :size="size">
      <el-option v-for="item in citys" :key="item.name" :label="item.name" :value="item"></el-option>
    </el-select>
    <el-select v-model="selectDistrict"  value-key="name" @change="changeDistrict($event)" placeholder="区" style="margin-right: 5px;width: (100 / this.number)%;" v-show="num!=undefined&&num>=3" :size="size">
      <el-option v-for="item in districts" :key="item.name" :label="item.name" :value="item"></el-option>
    </el-select>
    <el-select v-model="selectStreet" value-key="name" @change="changeStreet($event)" placeholder="街道" style="width: (100 / this.number)%;"  v-show="num!=undefined&&num>=4" :size="size">
      <el-option v-for="item in strees" :key="item.name" :label="item.name" :value="item"></el-option>
    </el-select>
  </div>
</template>

<script>
import { getGaoDeAddress } from '../../api/api'
export default {
  props: {
    city: {
      type: String,
      default: ''
    },
    province: {
      type: String,
      default: ''
    },
    district: {
      type: String,
      default: ''
    },
    street: {
      type: String,
      default: ''
    },
    num:{
      type: Number,
      default: 4
    },
    size: {
      type: String,
      default: ''
    },
  },
  watch: {
    province: {
      handler(val) {
        this.selectProvince = val;
      },
      deep: true
    },
    city: {
      handler(val) {
        this.selectCity = val;
      },
      deep: true
    },
    district: {
      handler(val) {
        this.selectDistrict = val;
      },
      deep: true
    },
    street: {
      handler(val) {
        this.selectStreet = val;
      },
      deep: true
    }
  },
  data() {
    return {
      number:4,
      selectCity: '',//市的选中值
      selectProvince: '',//省的选中值
      selectDistrict: '',//区的选中值
      selectStreet: '',//街道的选中值
      adcode:'',
      provinces: [],//省的option值
      citys: [],//市的option值
      districts: [],//区的option值
      strees: [],//街道的option值
    }
  },
  created(){
  },
  mounted() {
    this.addressutil();//获取省option
    this.initfunc();
  },
  methods: {
    initfunc() {
      this.selectCity = this.city;
      this.selectProvince = this.province;
      this.selectDistrict = this.district;
      this.selectStreet = this.street;
      this.number = this.num;
    },
    //初始化省数据
    addressutil() {
      let params = {
        subdistrict: 1, //因为浏览器接收数据有限制，所以先设置获取一级数据
      };
      getGaoDeAddress(params).then((res) => {
        const data = res.data.districts;
        this.provinces = data[0].districts;
      });
    },
    //监听省下拉框
    changeProvice(event) {
      let params = {
        subdistrict: 1,
        keywords: event.name,
      };
      this.selectProvince = event.name;
      this.selectCity = '';
      this.selectDistrict = '';
      this.selectStreet = '';
      this.adcode = event.adcode
      this.changeAddress(1);//地址改变事件
      getGaoDeAddress(params).then((res) => {
        const data = res.data.districts;
        data.forEach((item, index) => {
          if (event.adcode == item.adcode) {
            this.citys = data[index].districts;
          }
        });

        if (this.citys.length == 0) {
          this.citys.push({ adcode: "", name: "无数据" });
          this.selectCity = '';
        }
      });
    },
    changeCity(event) {
      let params = {
        subdistrict: 1,
        keywords: event.name,
      };
      this.selectCity = event.name;
      this.selectDistrict = '';
      this.selectStreet = '';
      this.adcode = event.adcode
      this.changeAddress(2);//地址改变事件
      getGaoDeAddress(params).then((res) => {
        const data = res.data.districts;
        data.forEach((item, index) => {
          if (event.adcode == item.adcode) {
            this.districts = data[index].districts;
          }
        });

        if (this.districts.length == 0) {
          this.districts.push({ adcode: "", name: "无数据" });
          this.selectDistrict = '';
        }
      });
    },

    changeDistrict(event) {
      let params = {
        subdistrict: 1,
        keywords: event.name,
      };
      this.selectDistrict = event.name;
      this.selectStreet = "";
      this.adcode = event.adcode
      this.changeAddress(3);//地址改变事件
      getGaoDeAddress(params).then((res) => {
        const data = res.data.districts;
        data.forEach((item, index) => {
          if (event.adcode == item.adcode) {
            this.strees = data[index].districts;
          }
        });

        if (this.strees.length == 0) {
          this.strees.push({ adcode: "", name: "无数据" });
          this.selectStree = "";
        }
      });
    },
    changeStreet(event) {
      this.selectStreet = event.name;
      this.adcode = event.adcode
      this.changeAddress(4);//地址改变事件
    },
    /**
     * 地址改变事件
     */
    changeAddress(level) {
      this.$emit('adr-change', {
        level: level,
        selectProvince: this.selectProvince,//省的选中值
        selectCity: this.selectCity,//市的选中值
        selectDistrict: this.selectDistrict,//区的选中值
        selectStreet: this.selectStreet,//街道的选中值
        adcode: this.adcode
      })
    },
  }
}
</script>

<style>
</style>